import axios from 'axios';

const httpRequest = async (url, method = 'get', body = null, others) => {

    try {
        const response = await axios({
            url,
            method,
            data: body ,
            headers: {
                appKey: "selfcare",
                api_key:"intllgr1",
                api_secret:"F08F7D9FB3F34862E26A4063C5C566AB"
            },
            ...others,
        });

        // console.log('response', response);
        return response;
    } catch (error) {
        console.log('error', error.message);
        return error.response;
    }
};

export default httpRequest;