import { combineReducers } from 'redux';
import userSlice from './userSlice';
import product from './product';
import memberslice from './memberslice';
import onboard from './onboard';

const indexSlice = combineReducers({
    user: userSlice,
    products: product,
    onboard: onboard,
    members: memberslice,
  });
  
  export default indexSlice;