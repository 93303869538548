import { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormProvider from '../../hook-form';
import CheckoutDelivery from './CheckoutDelivery';
import CheckoutBillingInfo from './CheckoutBillingInfo';
import { ArrowBack } from '@mui/icons-material';
import {  previousStep } from "../../../redux/slices/product";
import { useDispatch, useSelector } from 'react-redux';
import { completeLoanRequest } from '../../../services/loanService';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';


export default function CheckoutPayment() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

const dispatch = useDispatch()
const { enqueueSnackbar } = useSnackbar();

const customer = useSelector((state) => state?.onboard?.customer);
const loanId = useSelector(
  (state) => state?.user?.user?.loanApplication?.loanId
);
const deviceTypeId = useSelector(
  (state) => state.onboard?.product?.deviceTypeId
);

const sign = useSelector((state) => state?.products?.sign);


const makeLoanRequest = async() => {
  setLoading(true)
const payload = {
      customer,
      corporate: {
        corporateId: 20,
      },
      loan: { loanId },
      device: { deviceTypeId },
      signature: {
        data: sign,
        extension: "image/png",
      },
      photo: {
        file: customer.avatarUrl,
        extension: "image/jpeg",
        type: "profile"
      },
    };

    // delete payload.customer.avatarUrl;
console.log(payload);

    const res = await completeLoanRequest(payload);
    console.log(res);
    if(res?.status) {
      navigate("/success")
    }else{
      enqueueSnackbar(
        `${res?.message ? res?.message : 'OOPS!!! Something went wrong. Please try again'}`,
        {
          variant: 'error',
          persist: false,
        }
      );
    }

    setLoading(false)
}

  return (
    <FormProvider>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <CheckoutDelivery  />
          <Button
              style={{ backgroundColor: "#000", color: "#fff", marginTop: 20 }}
              onClick={() => dispatch((previousStep()))}
              size="large"
              startIcon={<ArrowBack />}
              variant="contained"
            >
              Previous
            </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          <CheckoutBillingInfo />
          <LoadingButton
            fullWidth
            size="large"
            onClick={makeLoanRequest}
            variant="contained"
            loading={loading}
          >
            Make Loan Request
          </LoadingButton>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
