import React from "react";
import Register from "./screens/Register";
import { Routes, Route } from "react-router-dom";
import CustomerLogin from "./screens/CustomerLogin";
import Dashboard from "./screens/Dashboard";
import ProtectedRoutes from "./components/routes/ProtectedRoute";
import BundleView from "./screens/BundleView";
import OnboardWizard from "./screens/OnboardWizard";
import AdminLogin from "./screens/AdminLogin";
import Members from "./screens/Members";
import { useSelector } from "react-redux";
import LoanApplicationSucess from "./components/onboard/LoanApplicationSucess";
import Partners from "./screens/Partners";

const App = () => {
  // const role = useSelector((state) => state?.user?.currentUser?.role);

  return (
    <Routes>
      <Route exact path="/" element={<Partners />} />
      <Route element={<ProtectedRoutes />}>
        <Route path="/home" element={<Dashboard />} />
        <Route path="/device" element={<BundleView />} />
        <Route path="/onboard" element={<OnboardWizard />} />
        <Route path="/success" element={<LoanApplicationSucess />} />
      </Route>
    </Routes>
  );
};

export default App;
