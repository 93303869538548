import { useState } from "react";
import AuthLayout from "../components/layouts/AuthLayout";
import CustomSelect from "../components/CustomSelect";
import CustomerLoginForm from "../components/CustomerLoginForm";
import { Stack, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import RegisterForm from "../components/RegisterForm";

const Partners = () => {
  const [level, setLevel] = useState(1);
  return (
    <AuthLayout>
      {level === 1 && (
        <div>
          <h3 style={{ textAlign: "center" }}>Select your Organization</h3>
          <CustomSelect
            placeholder="Pick a company"
            options={[
              { id: 2, name: "Sacco" },
              { id: 3, name: "Ecobank" },
            ]}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            onChange={(selectedOption) => setLevel(Number(selectedOption?.id))}
          />
        </div>
      )}

      {level === 2 && <CustomerLoginForm />}
      {level === 3 && <RegisterForm />}

      {level !== 1 && (
        <Stack
          direction="row"
          spacing={0.5}
          justifyContent="center"
          marginTop={5}
          onClick={() => setLevel(1)}
        >
          <Typography variant="body2"> Go back to</Typography>

          <span
            style={{ cursor: "pointer" }}
            className="otherLink"
            variant="subtitle2"
          >
            company choices
          </span>
        </Stack>
      )}
    </AuthLayout>
  );
};

export default Partners;
