import {createSlice } from '@reduxjs/toolkit'

const initialState = {
    user: null,
    currentUser: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        saveUser: (state, action) => {
            state.user = action.payload
        },
        removeUser: (state) => {
            state.user = null
        },
        addCurrentUser: (state, {payload}) => {
            state.currentUser = payload
        },
        removeCurrentUser: (state) => {
            state.currentUser = null
        }
    }
})

export default userSlice.reducer
export const {saveUser, removeUser, addCurrentUser, removeCurrentUser} = userSlice.actions
