import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../config/settings';
import httpRequest from '../../utils/httpRequest';
const initialState = {
  products: [],
  loading: false,
  error: null,
  activeStep: 0,
  sign: ""
};

export const getProducts = createAsyncThunk('products/fetch', async (arg) => {
  return httpRequest(`${BASE_URL}/getmsisdnplans?msisdn=${arg}`,);
});

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    nextStep(state, { payload }) {
      state.activeStep = state.activeStep + 1;
    },
    previousStep(state, { payload }) {
      state.activeStep = state.activeStep - 1;
    },
    saveSignature(state, { payload }) {
      state.sign = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      state.loading = true;
      state.products = payload?.data?.offers;
    });
    builder.addCase(getProducts.rejected, (state) => {
      console.log('failed');
      state.loading = false;
      state.products = [];
      state.error = 'Error, Failed to load';
    });
  },
});

export default productSlice.reducer;
export const { nextStep, previousStep, saveSignature } = productSlice.actions;
