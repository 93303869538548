import { useSelector } from "react-redux";

const { Stack, Typography } = require("@mui/material");



// eslint-disable-next-line react/prop-types
const ProductDesctiption = () => {
  const product = useSelector(state => state.onboard?.product)


  const { specs, plans } = product;

  console.log("specs", specs);

  const descArray = [
    { title: "Device", key: specs?.description },
    { title: "Dimension", key: specs?.dimension },
    { title: "Display", key: specs?.display },
    { title: "Screen Size", key: specs?.screen_size },
    { title: "Memory", key: specs?.memory },
    { title: "Internal Storage", key: specs?.internal_storage },
    { title: "Camera", key: specs.camera },
  ];


  return (
    <>
      {descArray.map((item) => (
        <Stack sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle2">{item.title}</Typography>

          <Typography sx={{ color: "text.secondary", fontSize: 12 }}>
            {item.key}
          </Typography>
        </Stack>
      ))}
    </>
  );
};

export default ProductDesctiption;
