import { Card, CardHeader, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import DashboardLayout from '../layouts/DashboardLayout';


const LoanApplicationSucess = () => {
    const user = useSelector((state) => state?.user?.currentUser);
    return (
      <DashboardLayout>
        <Card>
        <CardHeader title="Request Successfull" subheader="Your loan request has been submitted" />

        <Typography sx={{ p: 3, color: 'text.secondary' }}>
         Dear {user?.displayName}. Your  loan application request is being processed. You will be notified once a decision has been concerning the application. 
        </Typography>
        <Typography sx={{ p: 3, color: 'text.secondary' }}>
        Thank you for your patronage.
        </Typography>
      </Card>
      </DashboardLayout>
    );
};

export default LoanApplicationSucess;