import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function useActiveLink(path, deep = true) {
  console.log('path', path);
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [isExternalLink, setIsExternalLink] = useState(false);

  useEffect(() => {
    const checkPath = path.startsWith('#');
    const currentPath = path === '/dashboard' ? '/dashboard' : `${path}/`;

    console.log('currentPath', location.pathname);

    const normalActive =
      (!checkPath && location.pathname === currentPath) ||
      (!checkPath && location.pathname === currentPath);

    const deepActive =
      (!checkPath && location.pathname.includes(currentPath)) ||
      (!checkPath && location.pathname.includes(currentPath));

    setActive(deep ? deepActive : normalActive);
    setIsExternalLink(path.includes('http'));
  }, [location.pathname, path, deep]);

  return {
    active,
    isExternalLink,
  };
}
