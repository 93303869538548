import { Card, Button, Typography, CardHeader, CardContent } from '@mui/material';
import Iconify from '../../iconify';
import { useDispatch, useSelector } from 'react-redux';
import { previousStep } from '../../../redux/slices/product';


export default function CheckoutBillingInfo() {
  const customer = useSelector((state) => state?.onboard?.customer);
  const dispatch = useDispatch()
  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader
        title="User information"
        action={
          <Button size="small" onClick={() => {dispatch(previousStep()); dispatch(previousStep())}} startIcon={<Iconify icon="eva:edit-fill" />}>
            Edit
          </Button>
        }
      />
      <CardContent>
        <Typography variant="body2" gutterBottom>
          Name: {customer?.name} {customer?.surname}&nbsp;
        </Typography>

        <Typography variant="body2" gutterBottom>
          National ID: {customer?.nationalIdNumber}
        </Typography>

        <Typography variant="body2" gutterBottom>
          Phone: {customer?.msisdn}
        </Typography>

        <Typography variant="body2" gutterBottom>
          Address: {customer?.address}
        </Typography>
      </CardContent>
    </Card>
  );
}
