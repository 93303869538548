import rwandaData from "../data/rwandaData.json"

export const getDistricts = (province) => {
    if (!province) return [];
  
    return Object.keys(rwandaData[province]);
  };
  
  export const getSectors = (province, district) => {
    if (!province || !district) return [];
  
    return Object.keys(rwandaData[province][district] ? rwandaData[province][district] : []);
  };
  
  export const getCells = (province, district, sector) => {
    if (!province || !district || !sector) return [];
  
    return Object.keys(
      rwandaData[province][district] && rwandaData[province][district][sector]
        ? rwandaData[province][district][sector]
        : []
    );
  };