import { useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "./hook-form/FormProvider";
import { Alert, FormHelperText, Stack, Typography } from "@mui/material";
import { RHFCodes, RHFTextField } from "./hook-form";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import {
  validateCorporateCustomer,
  validateCorporateCustomerOTP,
  validateTeacher,
} from "../services/authService";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { addCurrentUser, saveUser } from "../redux/slices/userSlice";
import userAvatar from "../assets/images/user.png";

const CustomerLoginForm = () => {
  const [otpCodeSent, setOtpCodeSent] = useState({ status: false, id: "" });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    msisdn: Yup.string().matches(
      /^07[89]\d{7}$/,
      "Invalid phone number. Your msisdn is 10 digit that starts with 078 0r 079"
    ),
  });

  const defaultValues = {
    msisdn: "",
    accountNumber: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;
  // const idNumber = watch("id", false);

  const validateTeacherAccount = async (data) => {
    const res = (await validateTeacher(`25${data.msisdn}`, data.accountNumber))
      ?.data;
    if (res?.status && res?.data) {
      dispatch(saveUser({ ...res?.data, msisdn: data.msisdn}));
      enqueueSnackbar(
        `Hello ${res?.data?.saccoAccountName}, Your account has been validated.`,
        {
          variant: "success",
          persist: false,
        }
      );
      navigate("/home");
    } else {
      enqueueSnackbar(
        `${
          res?.message
            ? res?.message
            : "OOPS!!! Something went wrong. Please try again"
        }`,
        {
          variant: "error",
          persist: false,
        }
      );
    }
  };

  const onSubmit = async (data) => {
    console.log("second", data);
    const otp = Object.entries(data)
      .filter(([key, value]) => key !== "msisdn" && value !== "")
      .map(([key, value]) => `${value}`)
      .join("");

    if (otp?.length < 6) {
      enqueueSnackbar("Invalid OTP", {
        variant: "error",
        persist: false,
      });
      return;
    }

    const res = (await validateCorporateCustomerOTP(`25${data.msisdn}`, otp))
      ?.data;

    if (res?.status) {
      dispatch(saveUser(res?.data));
      dispatch(
        addCurrentUser({
          displayName: `${res?.data?.customer?.firstName} ${res?.data?.customer?.surname}`,
          photo: res?.data?.photo ? res?.data?.photo : userAvatar,
          email: res?.data?.customer?.email,
          role: "customer",
        })
      );
      navigate("/home");
      // reset();
    } else {
      enqueueSnackbar(
        `${
          res?.message
            ? res?.message
            : "OOPS!!! Something went wrong. Please try again"
        }`,
        {
          variant: "error",
          persist: false,
        }
      );
    }
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(validateTeacherAccount)}
    >
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <RHFTextField
          name="msisdn"
          required
          type="number"
          label="Phone Number"
          placeholder="Enter your phone number (07xxxxxxxx)"
          inputProps={{ maxLength: 10 }}
        />
        <RHFTextField
          name="accountNumber"
          required
          type="number"
          label="Account Number"
          placeholder="Enter your account number"
        />

        {/* {otpCodeSent.status && (
          <Stack spacing={2} alignItems="center">
            <Typography sx={{ color: 'text.secondary', mt: 3 }}>
              We&apos;ve sent a 6-digit confirmation code to your phone number.
              <br />
              Please enter the code in below box to verify your account.
            </Typography>
            <RHFCodes
              keyName="code"
              inputs={['code1', 'code2', 'code3', 'code4', 'code5', 'code6']}
            />

            {(!!errors.code1 ||
              !!errors.code2 ||
              !!errors.code3 ||
              !!errors.code4 ||
              !!errors.code5 ||
              !!errors.code6) && (
              <FormHelperText error sx={{ px: 2 }}>
                Code is required
              </FormHelperText>
            )}
            <Typography variant="body2" sx={{ mt: 3 }}>
              Don’t have a code? &nbsp;
              <Link onClick={handleSubmit(onSendOtp)} variant="subtitle2">Resend code</Link>
            </Typography>
          </Stack>
        )} */}
      </Stack>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{
          my: 3,
          bgcolor: "primary.main",
          color: (theme) =>
            theme.palette.mode === "light" ? "common.black" : "grey.800",
          "&:hover": {
            bgcolor: "primary.main",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
          },
        }}
      >
        proceed
      </LoadingButton>

      {/* {otpCodeSent.status && (
        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            my: 3,
            bgcolor: "primary.main",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.black" : "grey.800",
            "&:hover": {
              bgcolor: "primary.main",
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          Login
        </LoadingButton>
      )} */}
    </FormProvider>
  );
};

export default CustomerLoginForm;
