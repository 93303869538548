import { BASE_URL, SHARED_BASE_URL } from '../config/settings';
import httpRequest from '../utils/httpRequest';

export const approvedDevices = async (id) => {
    return await httpRequest(`${BASE_URL}/get-corporate-approved-devices`, 'post', { id });
  };

  export const loanApplication = async (payload) => {
    return await httpRequest(`${BASE_URL}/corporate-customer-loan-application`, 'post', payload);
  };

  export const loanRequestApprove = async (payload) => {
    return await httpRequest(`${BASE_URL}/loans/approve-corporate-customer-loan`, 'post', payload);
  };

  export const loanRequestReject = async (payload) => {
    return await httpRequest(`${BASE_URL}/loans/reject-corporate-customer-loan`, 'post', payload);
  };

  export const completeLoanRequest = async (payload) => {
    return await httpRequest(`${BASE_URL}/loans/complete-corporate-loan-application`, 'post', payload);
  };

  export const selectBundle = async (payload) => {
    return await httpRequest(`${SHARED_BASE_URL}/Customers/update_journey`, 'post', payload);
  }