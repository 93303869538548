import {useState} from 'react';
import { useSettingsContext } from '../../themeSettings';
import useResponsive from '../../hooks/useResponsive';
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import { Box } from '@mui/material';


const DashboardLayout = ({ children }) => {

    const { themeLayout } = useSettingsContext();

    const isDesktop = useResponsive('up', 'lg');
  
    const [open, setOpen] = useState(false);
  
    const isNavHorizontal = themeLayout === 'horizontal';
  
    const isNavMini = themeLayout === 'mini';
  
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;
    const renderContent = () => {
        if (isNavHorizontal) {
          return (
            <>
              <Header onOpenNav={handleOpen} />
    
              {isDesktop ? <NavHorizontal /> : renderNavVertical}
    
              <Main>{children}</Main>
            </>
          );
        }
    
        if (isNavMini) {
          return (
            <>
              <Header onOpenNav={handleOpen} />
    
              <Box
                sx={{
                  display: { lg: 'flex' },
                  minHeight: { lg: 1 },
                }}
              >
                {isDesktop ? <NavMini /> : renderNavVertical}
    
                <Main>{children}</Main>
              </Box>
            </>
          );
        }
    
        return (
          <>
            <Header onOpenNav={handleOpen} />
    
            <Box
              sx={{
                display: { lg: 'flex' },
                minHeight: { lg: 1 },
              }}
            >
              {renderNavVertical}
    
              <Main>{children}</Main>
            </Box>
          </>
        );
      };

    return <>{renderContent()}</>
};

export default DashboardLayout;