// @mui
import { styled, alpha } from '@mui/material/styles';
// utils
import { bgGradient } from './CssStyles';

// ----------------------------------------------------------------------

export const StyledRoot = styled('main')(() => ({
  height: '100%',
  display: 'flex',
  position: 'relative',
}));

export const StyledSection = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

export const StyledSectionBg = styled('div')(({ theme }) => ({
  backgroundColor: 'hsla(0,0%,100%,1)',
  backgroundImage: `
radial-gradient(at 0% 4%, hsla(48,96%,59%,0.24) 0px, transparent 50%),
radial-gradient(at 0% 73%, hsla(48,96%,59%,0.37) 0px, transparent 50%),
radial-gradient(at 97% 60%, hsla(48,100%,90%,1) 0px, transparent 50%)`,
  top: 0,
  left: 0,
  zIndex: -1,
  width: '100%',
  minHeight: '100vh',
  position: 'absolute',
  transform: 'scaleX(-1)',
}));

export const StyledContent = styled('div')(({ theme }) => ({
  width: 600,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  justifyContent: 'center',
  padding: theme.spacing(15, 2),
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    padding: theme.spacing(30, 8, 0, 8),
  },
}));
