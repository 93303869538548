import { Typography, Stack, Box } from '@mui/material';
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from "../styled/RootStyles"
import Logo from './Logo';
import Image from '../Image';
import illustrationDashboard from "../../assets/images/illustration_dashboard.png"
import sacco from "../../assets/images/sacco.jpeg"

const AuthLayout = ({ children, illustration, title }) => {
    return (
        <StyledRoot>
        <Logo
          sx={{
            zIndex: 9,
            position: 'absolute',
            mt: { xs: 1.5, md: 5 },
            ml: { xs: 2, md: 5 },
          }}
        />
  
        <StyledSection>
          <Stack direction="row" alignItems="center" sx={{ mb: 10 }}>
            {/* <Box
              component="img"
              src={sacco}
              sx={{ width: 50, height: 50, cursor: 'pointer', borderRadius: '5px' }}
            /> */}
            <Typography variant="h4" sx={{ marginLeft: 2 }}>
              Intelligra Device Financing
            </Typography>
          </Stack>
          <Image
            disabledEffect
            visibleByDefault
            alt="auth"
            src={illustration || illustrationDashboard}
            sx={{ maxWidth: 600 }}
          />
          <StyledSectionBg />
        </StyledSection>
  
        <StyledContent>
          <Stack sx={{ width: 1 }}> {children} </Stack>
        </StyledContent>
      </StyledRoot>
    );
};

export default AuthLayout;