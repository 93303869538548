import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import storeInit from "./redux/store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "./theme";
import { SettingsProvider, ThemeSettings } from "./themeSettings";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={storeInit.store}>
      <PersistGate loading={null} persistor={storeInit.persistor}>
        <BrowserRouter>
          <SettingsProvider>
            <ThemeProvider>
              <ThemeSettings>
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  maxSnack={1}
                >
                  <App />
                </SnackbarProvider>
              </ThemeSettings>
            </ThemeProvider>
          </SettingsProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
