import { Grid, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSettingsContext } from '../themeSettings';
// sections
import {
  CheckoutSteps,
  CheckoutPayment,
  CheckoutOrderComplete,
} from '../components/onboard';
import TermsAndConditions from '../components/onboard/TermsAndConditions';
import UserNewEditForm from '../components/onboard/UserNewEditForm';
import DashboardLayout from '../components/layouts/DashboardLayout';
import LoanApplicationSucess from '../components/onboard/LoanApplicationSucess';

const OnboardWizard = () => {
    const { themeStretch } = useSettingsContext();
    const STEPS = ['KYC', 'T&C', 'Loan'];
    const activeStep = useSelector((state) => state.products?.activeStep);
    const completed = activeStep === STEPS.length;

    return (
        <DashboardLayout>
        <Container maxWidth={themeStretch ? false : 'lg'}>
        <Grid container justifyContent={completed ? 'center' : 'flex-start'}>
          <Grid item xs={12} md={8}>
            <CheckoutSteps activeStep={activeStep} steps={STEPS} />
          </Grid>
        </Grid>

        {completed ? (
          <CheckoutOrderComplete open={completed}/>
        ) : (
          <>
            {activeStep === 0 && <UserNewEditForm />}

            {activeStep === 1 && <TermsAndConditions />}

            {activeStep === 2 && <CheckoutPayment />}
          </>
        )}
      </Container>
      </DashboardLayout>
    );
};

export default OnboardWizard;