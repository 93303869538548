// @mui
import { Stack, Button, Typography, Box } from '@mui/material';

export default function NavDocs() {


  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 1,
        mt: 10,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}
    >
      {/* <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
        Copyright © 2023 MTN Rwanda
      </Typography> */}
    </Stack>
  );
}
