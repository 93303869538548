import { useState, useEffect } from "react";
import orderBy from "lodash/orderBy";
import { useForm } from "react-hook-form";
import { Container, Typography, Stack } from "@mui/material";
import FormProvider from "../components/hook-form";
import ShopProductSearch from "../components/shop/ShopProductSearch";
import ShopFilterDrawer from "../components/shop/ShopFilterDrawer";
import ShopProductSort from "../components/shop/ShopProductSort";
import ShopTagFiltered from "../components/shop/ShopTagFiltered";
import ShopProductList from "../components/shop/ShopProductList";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../redux/slices/product";
import DashboardLayout from "../components/layouts/DashboardLayout";

const Dashboard = () => {
  const dispatch = useDispatch();

  const products = useSelector((state) => state.products?.products);
  const loading = useSelector((state) => state.products?.loading);

  const user = useSelector((state) => state.user?.user);


  const [openFilter, setOpenFilter] = useState(false);

  const defaultValues = {
    gender: [],
    category: "All",
    colors: [],
    priceRange: [0, 200],
    rating: "",
    sortBy: "featured",
  };

  const methods = useForm({
    defaultValues,
  });

  const {
    reset,
    watch,
    formState: { dirtyFields },
  } = methods;

  const isDefault =
    (!dirtyFields.type_name &&
      !dirtyFields.internal_storage &&
      !dirtyFields.screen_size &&
      !dirtyFields.battery &&
      !dirtyFields.os_ui) ||
    false;

  const values = watch();

  const dataFiltered = applyFilter(products, values);

  useEffect(() => {
    dispatch(
      getProducts(`25${user?.msisdn}`)
    );
  }, [dispatch]);

  const handleResetFilter = () => {
    reset();
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  function applyFilter(products, filters) {
    const { category, priceRange, sortBy } = filters;

    const min = priceRange[0];

    const max = priceRange[1];

    if (sortBy === "priceDesc") {
      products = orderBy(products, ["full_price"], ["desc"]);
    }

    if (sortBy === "priceAsc") {
      products = orderBy(products, ["full_price"], ["asc"]);
    }

    if (category !== "All") {
      products = products.filter(
        (product) =>
          product.type_name &&
          product.type_name.toLowerCase().includes(category.toLowerCase())
      );
    }

    if (min !== 0 || max !== 200) {
      products = products.filter(
        (product) => product.price >= min && product.price <= max
      );
    }

    return products;
  }



  return (
    <DashboardLayout>
      <FormProvider methods={methods}>
        <Container maxWidth={false}>
          <Stack
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ sm: "center" }}
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <ShopProductSearch />
{/* 
            <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
              <ShopFilterDrawer
                isDefault={isDefault}
                open={openFilter}
                onOpen={handleOpenFilter}
                onClose={handleCloseFilter}
                onResetFilter={handleResetFilter}
              />

              <ShopProductSort />
            </Stack> */}
          </Stack>

          <Stack sx={{ mb: 3 }}>
            {!isDefault && (
              <>
                <Typography variant="body2" gutterBottom>
                  <strong>{dataFiltered.length}</strong>
                  &nbsp;Products found
                </Typography>

                <ShopTagFiltered
                  isFiltered={!isDefault}
                  onResetFilter={handleResetFilter}
                />
              </>
            )}
          </Stack>

          <ShopProductList
            products={dataFiltered}
            loading={products?.length < 1 && loading}
          />
        </Container>
      </FormProvider>
    </DashboardLayout>
  );
};

export default Dashboard;
