import Select from "react-select";
const CustomSelect = ({
    name,
    initialValue,
    label,
    placeholder,
    error,
    touched,
    options,
    onChange,
    disabled,
    getOptionLabel,
    getOptionValue,
    isRequired = true,
    isMulti = false,
    borderColor = "#000"
  }) => {
    return (
        <>
         {/* <label className="form-label">{label} {isRequired && (<span className="text-danger">*</span>)}</label> */}
        <Select
        placeholder={placeholder}
        name={name}
        required={isRequired}
        value={initialValue}
        isMulti={isMulti}
        isDisabled={disabled}
        // className="form-control border-success"
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={onChange}
        options={options}
        isRequired={isRequired}
        styles={{
            container: (baseStyles) => ({
              ...baseStyles,
            //   flexGrow: 1,
            }),
            control: (provided) => ({
                ...provided,
                border: 0,
                outline: `1px solid #ccc`,
                padding: 10
            }),
            menuList: (baseStyles) => ({
              ...baseStyles,
            }),
            option: (provided) => ({
              ...provided,
              color: "black",
            }),
          }}
      />
      </>
    );
};

export default CustomSelect;