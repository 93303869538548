import { useState } from "react";
import { alpha } from "@mui/material/styles";
import { Box, Stack, Typography, Divider } from "@mui/material";

import Iconify from "../iconify";
import FormProvider from "../hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectBundle } from "../../services/loanService";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

export default function ProductDetailsSummary({ product }) {
  const { specs, plans } = product;
  const user = useSelector((state) => state.user?.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const SUMMARY = [
    {
      title: "Interineti y'ukwezi",
      icon: "fluent-mdl2:internet-sharing",
      description: `${plans[0]?.bandleData}MB + ${plans[0]?.bandleSms} SMS + ${plans[0]?.bandleMinutes} Mins voice call `,
    },
    {
      title: "Theft insurance",
      icon: "ic:round-verified-user",
      description: "Get your phone insured from theft",
    },
    {
      title: "Screen replacement",
      icon: "material-symbols:screen-rotation-outline-rounded",
      description: "This covers a one-time replacement for cracks",
    },
    // {
    //   title: "Phone Replacement",
    //   icon: "tabler:replace",
    //   description: "In possible scenarios, the phone can be replaced",
    // },
  ];

  const selectDevice = async () => {
    setIsSubmitting(true);
    const payload = {
      msisdn: user?.msisdn,
      planId: Number(plans[0]?.id),
      deviceTypeId: Number(specs?.deviceTypeId),
    };
    const res = (await selectBundle(payload))?.data;
    console.log(res);
    enqueueSnackbar(
      res?.message,
      {
        variant: res?.status ? "success" : "error",
        persist: false,
      }
    );
    setIsSubmitting(false);
  };

  return (
    <FormProvider>
      <Stack
        spacing={3}
        sx={{
          p: (theme) => ({
            md: theme.spacing(5, 5, 0, 2),
          }),
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h5">{specs?.type_name}</Typography>
          <Typography variant="h4">
            Repayment: {`${plans[0]?.dailyPayment}Frw daily`}
          </Typography>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />
        <Box sx={{ my: 2, alignItems: "center" }}>
          {SUMMARY.map((item) => (
            <Box key={item.title} sx={{ display: "flex", mb: 1, mt: 3 }}>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  color: "primary.main",
                  marginRight: "15px",
                  bgcolor: (theme) =>
                    `${alpha(theme.palette.primary.main, 0.08)}`,
                }}
              >
                <Iconify icon={item.icon} width={25} />
              </Stack>
              <Stack>
                <Typography variant="subtitle2">{item.title}</Typography>

                <Typography sx={{ color: "text.secondary", fontSize: 12 }}>
                  {item.description}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack>
          <LoadingButton
            fullWidth
            size="large"
            color="warning"
            variant="contained"
            onClick={selectDevice}
            sx={{ whiteSpace: "nowrap" }}
            loading={isSubmitting}
          >
            Select Device
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
