import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import mtnlogo from "../../assets/images/logo.jpeg"

const Logo = forwardRef(({ disabledLink = false, full = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const logo = (
    <Box
      component="img"
      src={mtnlogo}
      sx={{
        width: 60,
        height: 60,
        cursor: 'pointer',
        borderRadius: '5px',
        ...sx,
      }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link  href="/" sx={{ display: 'contents', flexDirection: 'row' }}>
      {full ? (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {logo}
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'pre-line',
              color: theme.palette.primary.contrastText,
              fontSize: '1rem',
              fontWeight: 'bold',
              marginLeft: 1,
              textAlign: 'center',
            }}
          >
            Corporate Portal
          </Typography>
        </Box>
      ) : (
        logo
      )}
    </Link>
  );
});

export default Logo;
