import { useEffect, useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// next

// @mui
import { Link, Typography, Autocomplete, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Image from '../Image';
import Iconify from '../iconify';
import { CustomTextField } from '../custom-input';
import SearchNotFound from '../search-not-found';
import { convertToHyphen } from '../../utils/format';
import { addProduct } from '../../redux/slices/onboard';
import { useNavigate } from 'react-router-dom';
import { IMAGE_URL } from '../../config/settings';

// ----------------------------------------------------------------------

export default function ShopProductSearch() {
  // const { push } = useRouter();
  const dispatch = useDispatch()
  const products = useSelector((state) => state.products.products);
  const navigate = useNavigate()

  const [searchProducts, setSearchProducts] = useState('');
  const [searchResults, setSearchResults] = useState(products);

  useEffect(() => {
    let timeoutId;
    const handleSearch = () => {
      setSearchResults(
        products.filter((product) =>
          product?.specs.type_name.toLowerCase().includes(searchProducts.toLowerCase())
        )
      );
    };

   

    if (searchProducts) {
      timeoutId = setTimeout(handleSearch, 500);
    } else {
      setSearchResults(products);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [products, searchProducts]);

  const handleChange = (value) => {
    setSearchProducts(value);
  };

  const handleGotoProduct = (product) => {
    dispatch(addProduct(product))
      navigate("/device")
    // push(PATH_DASHBOARD.eCommerce.view(convertToHyphen(product?.type_name)));
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      const product = products.find((item)=> item?.specs?.type_name === searchProducts)
      // handleGotoProduct(product);
    }
  };



  return (
    <Autocomplete
      size="small"
      autoHighlight
      popupIcon={null}
      options={searchResults}
      onInputChange={(event, value) => handleChange(value)}
      getOptionLabel={(option) => option.specs.type_name}
      noOptionsText={<SearchNotFound query={searchProducts} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      componentsProps={{
        popper: {
          sx: {
            width: `280px !important`,
          },
        },
        paper: {
          sx: {
            '& .MuiAutocomplete-option': {
              px: `8px !important`,
            },
          },
        },
      }}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          width={220}
          placeholder="Search..."
          onKeyUp={handleKeyUp}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, product, { inputValue }) => {
        const { type_name, photos } = product.specs;
        const matches = match(type_name, inputValue);
        const parts = parse(type_name, matches);



        return (
       
          <Link underline="none" onClick={() => handleGotoProduct(product)}>
             <li {...props}>
              <Image
                alt={type_name}
                src={`${IMAGE_URL}/${photos[0]?.deviceTypeId}.jpg`}
                sx={{ width: 48, height: 48, borderRadius: 1, flexShrink: 0, mr: 1.5 }}
              />

             
                {parts.map((part, index) => (
                  <Typography
                    key={index}
                    component="span"
                    variant="subtitle2"
                    color={part.highlight ? 'primary' : 'textPrimary'}
                  >
                    {part.text}
                  </Typography>
                ))}
       
            </li> 
            </Link>
     
        );
      }}
    />
  );
}
