import React, { useState, useRef } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
} from "@mui/material";
import SignaturePad from "react-signature-canvas";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { nextStep, previousStep, saveSignature } from "../../redux/slices/product";
import { fDate } from "../../utils/formatTime";

const TermsAndConditions = () => {
  const user = useSelector((state) => state?.user?.user?.customer);
  const loanId = useSelector(
    (state) => state?.user?.user?.loanApplication?.loanId
  );
  const device = useSelector(
    (state) => state.onboard?.product
  );

 
  let signPad = useRef({});
  const dispatch = useDispatch();

  const [isSigned, setIsSigned] = useState(false);

  const clear = () => {
    signPad.current.clear();
    setIsSigned(false);
  };
  return (

      <Grid container spacing={3}>
        <Card>
          <CardHeader
            title="TERMS AND CONDITIONS FOR MTN SERVICE BUNDLE SUBSCRIPTION AND INCLUDED
SMARTPHONE"
          />

          <CardContent>
            <Grid item xs={12} md={12}>
              <div style={{ marginBottom: 20 }}>
                <p>Customer Name: {user?.firstName} {user?.surname}</p>
                <p>Device: {device?.typeName}</p>
                <p>Date of Purchase : {fDate(new Date())}</p>
                <p>Loan ID: {loanId}</p>
              </div>
              <div style={{fontSize: 13}}>
              <p>

           
                <strong>General Terms and Conditions</strong>
              </p>
              <p>
                By signing this Subscription Agreement (the “Agreement”) between
                yourself, MTN Rwandacell Plc (“MTN”) and [Bank XYZ] (“Bank”),
                you, the undersigned identified buyer (“you” or “Buyer”), agree
                to purchase the phone (“the Device”), where the full repayment
                for the cost of the device is offset by a fixed monthly
                subscription to MTN Service Bundle (“Bundle Price”) over a
                period of 12 months (the “Tenor”), subject to the terms and
                conditions contained herein.
              </p>
              <p>
                The Bundle Price of {device?.fullPrice} RWF monthly quoted will be
                the committed sum payable each month for the duration of the
                Agreement and will not change unless you Default on your monthly
                repayments.
              </p>
              <p>
                The Bundle Price covers your monthly repayment towards the cost
                of the Device, as well as a Data, Voice and SMS bundle service
                provided monthly by MTN, your network provider. Each month, you
                are required to make payment through MTN Mobile Money (“MoMo”)
                Account deductions equal to the Bundle Price. The deductions can
                be made daily, weekly, or monthly and the full monthly amount
                must be made prior to and/or on the required due date but not
                later than 1 day prior to the repayment anniversary date. With
                this agreement, you hereby authorize MTN to deduct
                from your Momo Account towards your full monthly subscription.
              </p>
              <p>
                On the subscription anniversary date and following full
                subscription, MTN will provision the bundle for the following
                month.
              </p>
              <p>
                <i>
                  The terms “We” or “Us” shall refer to MTN and/or the Bank.
                </i>
              </p>
              <p>
                <strong>Customer/ Responsible Party</strong>
              </p>
              <p>
                Reference to “you” or “Buyer” means you are the individual and
                the customer on record. You understand and agree to be primarily
                responsible for and guarantee payment of any sums that become
                due under this Agreement. You are not allowed to sign on behalf
                of another individual, an organization, a business entity or
                government body and will be personally responsible for
                performance under this Agreement, if we subsequently discover
                the contrary.
              </p>
              <p>
                <strong> Device Approval.</strong>
              </p>
              <p>
                You hereby consent to provide any unique one-time password
                (“OTP”) approvals to the sales representatives/agents to
                facilitate the processing of financing for the Device.
              </p>
              <p>
                <strong>Initial Payment by Customer.</strong>
              </p>{" "}
              <p>
                You shall make the first monthly subscription via your MoMo
                Account payment at the Point-of-Sale in MTN and partner Stores.
                This is a prerequisite to the application being approved. Where
                an upfront payment is required as a condition of the
                subscription plan selected, such payment will also be made via
                Momo Account at the Point-of-Sale.
              </p>{" "}
              <p>
                <strong>Monthly Repayments.</strong>
              </p>{" "}
              <p>
                Subsequent subscriptions will be made via the
                daily/weekly/monthly deductions from the MoMo Account of the
                equivalent amount equal to the monthly Bundle Price, prior to
                the subscription anniversary date.
              </p>{" "}
              <p>
                On the monthly anniversary date, following full monthly
                subscription. MTN will provision the bundle offering – airtime,
                data and others for the following month.
              </p>{" "}
              <p>
                Under no circumstance would we be liable for any loss, expense
                or damage of any kind incurred by you due to non-compliance with
                this requirement.
              </p>{" "}
              <p>
                Failure to subscribe shall result in the Device being
                automatically disabled by us. You will be able to enable your
                phone upon full payment of past due subscription amounts (please
                follow the instructions in the FAQ document attached to the
                Device pack).
              </p>{" "}
              <p>
                Please note that the Bank reserves the right to report failure
                to repay to Credit Bureaus and any regulatory bodies or
                government institutions as applicable. All outstanding
                repayments will be collected by the Bank’s collection
                officers/agents and the Bank reserves the right to prosecute any
                customer for breach of contract.
              </p>{" "}
              <p>
                <strong>Device Protection.</strong>
              </p>{" "}
              <p>
                In the event of a theft, following presentation of required
                documentation to back up such claims of theft, the Bank will
                replace the smartphone with an equivalent smartphone at no cost
                to you. This phone replacement will however be limited to one
                occurrence in the 12-month contract period.
              </p>{" "}
              <p>
                However, below insurance amortization rules will be applied:
              </p>
              <ol type="i">
                <li>
                  in the event of phone replacement, affected customers will be
                  requested to pay a percentage of:
                  <ul style={{ marginLeft: 40 }}>
                    <li>
                      {" "}
                      20% for a device onboarded in MTN device financing in a
                      period between 0-6 months.{" "}
                    </li>

                    <li>
                      {" "}
                      30% for a device onboarded in MTN device financing in a
                      period between 7 Months to 12 months
                    </li>
                  </ul>
                </li>

                <li>
                  in the event of broken screen replacement, affected customers
                  will be requested to pay a percentage of:
                  <ul style={{ marginLeft: 40 }}>
                    <li>
                      {" "}
                      20% for a device onboarded in MTN device financing in a
                      period between 0-6 months
                    </li>

                    <li>
                      {" "}
                      30% for a device onboarded in MTN device financing in a
                      period between 7 Months to 12 months
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                Beyond this, you bear the entire risk of loss, theft, or damage
                to the Device from any cause during the term of the Agreement.
              </p>{" "}
              <p>
                The bank reserves the right to close the open loan contract with
                the equivalent value cost of the lost device at the time of loss
                and reserves the right to offer the customer an upgrade when
                this clause is triggered.{" "}
              </p>
              <p>
                <strong>E-signature.</strong>
              </p>
              <p>
                You hereby declare that the signature below is your original
                signature, which is to be relied upon for the purposes of
                verifying the authenticity of electronic versions of your
                signature in documents. You also hereby declare that this record
                of your signature may be used for the purposes of verifying the
                authenticity of electronic signatures that are set out in
                agreements or documents as being the true representation of your
                written signature. It is your duty to notify us immediately in
                the event that you change your signature. We shall not be liable
                for any liabilities, losses or damages as a result of your
                failure to comply with this requirement.
              </p>
              <p>
                <strong>Default.</strong>
              </p>{" "}
              <p>
                You will be in default of this Agreement if any one of the
                following events (referred to as an “Event of Default”) occurs:
              </p>
              <ol type="i">
                <li>
                  you fail to subscribe to thebundle by the applicable due date;
                </li>
                <li>
                  {" "}
                  you are in breach of any covenant, representation or warranty
                  in this Agreement;
                </li>
                <li> a bankruptcy petition is filed against you;</li>
                <li>
                  Where you are unable to pay any other party in accordance with
                  article 7 of the law No 075/2021 or 06/12/2021 relating to
                  insolvency in Rwanda.
                </li>
                <li>
                  you fail to disclose or provide false, misleading, or
                  incorrect information in your application for device finance
                  or during the Tenor of this Agreement;{" "}
                </li>
                <li>
                  {" "}
                  where you default in the performance or observance of any
                  other term, condition or covenant or performance of any other
                  obligation hereunder and such breach or default continues
                  un-remedied within ten days of the receipt of a notice of the
                  default, and{" "}
                </li>
                <li>
                  you port your phone number in respect of the Device to a
                  network other than MTN prior to the settlement of all your
                  repayments.{" "}
                </li>
              </ol>
              <p>
                Where you are in default under this Agreement, the outstanding
                balance of the Bundle Price for the remainder of the Tenor at
                the time will accelerate and immediately become due and payable.
                The Device will be disabled by us remotely and you will be
                unable to use the Device on any network.
              </p>{" "}
              <p>
                <strong> Remedies in the Event of Default.</strong>
              </p>
              <p>
                Upon an event of default, we have the right to exercise any one
                or more of the following remedies:
              </p>
              <ul type="i">
                <li>
                  disable the Device which will prevent you from using any of
                  the device functionality;
                </li>
                <li>
                  require you to immediately pay the outstanding unpaid amount
                  financed;
                </li>
                <li>require you to pay us the costs of collection; and </li>
                <li>
                  deposit enough funds in your Momo Account equivalent to the
                  monthly Bundle Price and then follow the instructions in the
                  FAQ document provided to you by the sales representative.{" "}
                </li>
                <li>
                  Deducting the outstanding unpaid amount from other phone
                  numbers registered on the same national ID/Passport of your
                  initial phone number used during MTN contractual agreement of
                  in the MTN device financing “Product name”with banks.{" "}
                </li>
              </ul>
              <p>
                <strong>Ownership of Device.</strong>
              </p>{" "}
              <p>
                The Bank shall have legal ownership of the Devices until full
                and final payment by you to the Bank by payment of the Bundle
                Price when due. Legal title to the Device shall pass to you only
                after making full and final payment to the Bank including any
                charges in respect of the Device.
              </p>
              <p>
                <strong>Non-Portability of Device</strong>
              </p>
              <p>
                You hereby agree that you shall not port the Device phone number
                to another telecommunications network operator until you have
                fully discharged all your obligations to the Bank and MTN under
                this Agreement. Failure to comply will result in your phone
                being disabled by MTN.
              </p>
              <p>
                <strong>Indemnity.</strong>
              </p>
              <p>
                You are responsible for any and all liabilities, losses or
                damages, whether imposed on you or us, which are directly or
                indirectly attributable to you or any of your acts or omissions
                while the Device is in your possession. This provision shall
                survive the termination of this Agreement. To the extent
                permitted by law, you will reimburse us for any expenses we
                incur in connection with the defense of any claim or action you
                are responsible for under this Agreement including but not
                limited to, actual and reasonable attorney fees.
              </p>{" "}
              <p>
                <strong>Governing Law and Jurisdiction.</strong>
              </p>
              <p>
                This Agreement and any claim, dispute or cause of action
                (whether in contract, tort or otherwise) based upon, arising out
                of or relating to this Agreement and the transactions
                contemplated hereby shall be governed by the laws of the
                Republic of Rwanda and referred to the courts of the Republic of
                Rwanda.
              </p>{" "}
              <p>
                <strong>Additional Terms.</strong>
              </p>{" "}
              <p>
                The Bank may, at any time, without your consent, assign or
                transfer this Agreement to an affiliate or third party. You may
                not assign this Agreement or any interest therein without the
                prior written consent of the Bank. We will check your credit
                record with a credit reporting bureau and your customer data
                records with MTN and you hereby consent to such processing, and
                you may not be able to purchase a Device under this Agreement if
                you do not meet the Bank’s applicable credit standards and
                criteria.
              </p>{" "}
              <p>
                <strong>Assignment and Disclosure of Information.</strong>
              </p>
              <p>
                You consent irrevocably to any future transfer and assignment by
                us of the loan, whether as part of a loan transfer scheme or
                otherwise. You also authorize us, that in the event of default,
                to disclose the outstanding balance due on the Devices and means
                of contacting you to credit reference agencies and collection
                agencies. You also authorize us and Bank’s partners to process
                your payment transaction and other data as part of this
                agreement.
              </p>{" "}
              <p>
                <strong>Customer Representations and Warranties.</strong>
              </p>{" "}
              <p>You represent and warrant as follows:</p>
              <ol type="1">
                <li>
                  The information given to us verbally, via email or other
                  electronic medium or in other form of writing as well as
                  documents submitted in support of the Device financing are
                  true, accurate and complete. You are solely responsible for
                  the correctness of such information. We shall not be liable
                  for relying on any documentation provided by you and also
                  accept no liability for consequences arising out of any
                  erroneous, incorrect or incomplete information supplied by
                  you. If you suspect that there is an error in the information
                  you have provided to us, you shall promptly inform us
                  accordingly without undue delay. We will endeavor to correct
                  the error whenever and wherever possible on a 'best effort'
                  basis but shall have no liability arising therefrom.
                </li>
              </ol>
              <p>
                This offer is subject to verification checks. In the event that
                your application is unsuccessful, your documents shall be
                returned to you.
              </p>{" "}
              <p>
                <strong>Memorandum of Acceptance</strong>
              </p>{" "}
              <p>
                I hereby accept the terms and conditions contained in this
                Subscription Agreement for Device Financing.
              </p>{" "}
              <p>
                I have read and understood the statements above and my signature
                hereunder or the One Time Password (“OTP”) that I have given to
                the sales representative/agent represents my true and authentic
                consent and is evidence of my agreement to be bound by the terms
                of this Agreement between myself, MTN and the Bank.
              </p>
              </div>
      
            </Grid>
            <Grid item xs={12} md={6} lg={4} style={{ marginTop: 40 }}>
           
              <Paper
                elevation={8}
                sx={{
                  p: 1.5,
                  flexGrow: 1,
                  bgcolor: "background.neutral",
                }}
              >
                {/* <small className="text-mute m-3">Sign here to accept the terms and conditions</small> */}
                <SignaturePad
                  ref={signPad}
                  onEnd={() => {
                    setIsSigned(true);
                  }}
                  penColor="black"
                  canvasProps={{
                    width: 500,
                    height: 150,
                    className: "sigCanvas",
                  }}
                />

                <Button onClick={clear} color="secondary" variant="contained">
                  Clear
                </Button>
              </Paper>
            </Grid>
          </CardContent>
        </Card>

        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="space-between"
        >
              <Button
              style={{ backgroundColor: "#000", color: "#fff" }}
              onClick={() => dispatch((previousStep()))}
              size="large"
              startIcon={<ArrowBack />}
              variant="contained"
            >
              Previous
            </Button>

          <Button
            disabled={!isSigned}
            size="large"
            endIcon={<ArrowForward />}
            variant="contained"
            onClick={() => {
              dispatch(saveSignature(signPad.current.toDataURL()));
              dispatch(nextStep());
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>

  );
};

export default TermsAndConditions;
