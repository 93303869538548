import Resizer from "react-image-file-resizer";

export const convertToHyphen = (str) => {
    let result = str.replace(/\s+/g, '-');
    result = result.replace(/\//g, '-');
    
    return result;
  }


  export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });




