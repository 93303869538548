import {createSlice } from '@reduxjs/toolkit';


const initialState = {
  product: null,
  customer: null
};

const onboardSlice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {
    addProduct(state, {payload}) {
      state.product = payload
    }, 
    removeProduct(state) {
      state.product = null
    },
    saveCustomer(state, {payload}) {
      state.customer = payload
    }
  },
});


export default onboardSlice.reducer;

export const {addProduct, removeProduct, saveCustomer} = onboardSlice.actions;


