import PropTypes from "prop-types";
// @mui
import { Box } from "@mui/material";
// components
import { SkeletonProductItem } from "../skeleton";
//
import ShopProductCard from "./ShopProductCard";

export default function ShopProductList({ products, loading }) {
  // console.log(loading);
  return (
    <Box
      gap={4}
      display="grid"
      gridTemplateColumns={{
        xs: "repeat(1, 1fr)",
        sm: "repeat(1, 1fr)",
        md: "repeat(2, 1fr)",
        lg: "repeat(2, 1fr)",
        xl: "repeat(3, 1fr)",
      }}
    >
      {loading
        ? [...Array(12)].map((index) => <SkeletonProductItem key={index} />)
        : products?.map((product, index) => (
            <>
              {product?.plans?.length > 0 && (
                <ShopProductCard
                  key={product?.spec?.deviceTypeId}
                  product={product}
                />
              )}
            </>
          ))}
    </Box>
  );
}
