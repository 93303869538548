import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../config/settings';
import httpRequest from '../../utils/httpRequest';
const initialState = {
  members: [],
  loading: false,
  error: null,
  activeStep: 0,
};

export const getmembers = createAsyncThunk('members/fetch', async (arg) => {
  return httpRequest(`${BASE_URL}/loans/get-corporate-customers-loan-application`, "post", arg);
});

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    updateMemberStatus(state, { payload }) {
      let updatedMembers = state.members.map(obj => {
        if (obj.loanId === payload.loanId) {
          return {...obj, status: payload.status};
        } else {
          return obj;
        }
      });
      state.members = updatedMembers
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getmembers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getmembers.fulfilled, (state, { payload }) => {
      state.loading = true;
      state.members = payload?.data?.list;
    });
    builder.addCase(getmembers.rejected, (state) => {
      console.log('failed');
      state.loading = false;
      state.members = [];
      state.error = 'Error, Failed to load';
    });
  },
});

export default membersSlice.reducer;
export const { updateMemberStatus } = membersSlice.actions;

