import { useState } from 'react';
import { Box, Tab, Tabs, Card, Grid, Divider, Container } from '@mui/material';
import {useSelector } from 'react-redux';
import { useSettingsContext } from '../themeSettings';
import ProductDesctiption from '../components/product/ProductDesctiption';
import ProductDetailsCarousel from '../components/product/ProductDetailsCarousel';
import ProductDetailsSummary from '../components/product/ProductDetailsSummary';
import DashboardLayout from '../components/layouts/DashboardLayout';



const BundleView = () => {
    const { themeStretch } = useSettingsContext();
    const product = useSelector(state => state.onboard?.product)
    const [currentTab, setCurrentTab] = useState('description');

    
  
    const TABS = [
      {
        value: 'description',
        label: 'description',
        component: product ? <ProductDesctiption product={product} /> : null,
      },
    ];

    return (
      <DashboardLayout>
        <Container maxWidth={themeStretch ? false : 'lg'}>
        {/* <CustomBreadcrumbs heading="Device Details" links={[{ name }]} sx={{ mb: 2 }} /> */}

        {product && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={7} sx={{ marginBottom: 2 }}>
                <ProductDetailsCarousel product={product} />
              </Grid>

              <Grid item xs={12} md={6} lg={5}>
                <ProductDetailsSummary
                  product={product}
                />
              </Grid>
            </Grid>

            <Card>
              <Tabs
                value={currentTab}
                onChange={(event, newValue) => setCurrentTab(newValue)}
                sx={{ px: 3, bgcolor: 'background.neutral' }}
              >
                {TABS.map((tab) => (
                  <Tab key={tab.value} value={tab.value} label={tab.label} />
                ))}
              </Tabs>

              <Divider />

              {TABS.map(
                (tab) =>
                  tab.value === currentTab && (
                    <Box
                      key={tab.value}
                      sx={{
                        ...(currentTab === 'description' && {
                          p: 3,
                        }),
                      }}
                    >
                      {tab.component}
                    </Box>
                  )
              )}
            </Card>
          </>
        )}
      </Container>
      </DashboardLayout>
    );
};

export default BundleView;