
import publicSansRegular from "../assets/fonts/Public_Sans/static/PublicSans-Regular.ttf"
import publicSansMedium from "../assets/fonts/Public_Sans/static/PublicSans-Medium.ttf"
import publicSansBold from "../assets/fonts/Public_Sans/static/PublicSans-Bold.ttf"
import publicSansExtraBold from "../assets/fonts/Public_Sans/static/PublicSans-ExtraBold.ttf"
import barlowExtraBold from "../assets/fonts/Barlow/Barlow-ExtraBold.ttf"

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

// const loadFonts = async () => {
//   try {
//     await Promise.all([
//       // Load Public Sans font
//       fetch('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600;700;800&display=swap')
//         .then((response) => response.text())
//         .then((fontCss) => {
//           const style = document.createElement('style');
//           style.innerHTML = fontCss;
//           document.head.appendChild(style);
//         }),

//       // Load Barlow font
//       fetch('https://fonts.googleapis.com/css2?family=Barlow:wght@900&display=swap')
//         .then((response) => response.text())
//         .then((fontCss) => {
//           const style = document.createElement('style');
//           style.innerHTML = fontCss;
//           document.head.appendChild(style);
//         })
//     ]);
//   } catch (error) {
//     console.error('Failed to load fonts:', error);
//   }
// };

// Load fonts when the document is ready
// if (document.readyState === 'loading') {
//   document.addEventListener('DOMContentLoaded', loadFonts);
// } else {
//   loadFonts();
// }

export const primaryFont = {
  fontFamily: 'Public Sans, sans-serif',
  fontWeight: {
    regular: publicSansRegular,
    medium: publicSansMedium,
    bold: publicSansBold,
    black: publicSansExtraBold
  },
};

export const secondaryFont = {
  fontFamily: 'Barlow, sans-serif',
  fontWeight: {
    bold: barlowExtraBold
  }
};

const typography = {
  fontFamily: primaryFont.fontFamily,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 800,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 800,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
};

export default typography;

// export const Typography = createGlobalStyle`

//   h1, h2, h3, h4, h5, h6 {
//     font-family: ${primaryFont.fontFamily};
//     font-weight: ${primaryFont.fontWeight.black};
//     margin-top: 0;
//     margin-bottom: 1rem;
//     line-height: 1.2;
//   }

//   h1 {
//     font-size: ${pxToRem(40)};
//     line-height: ${remToPx(80) / remToPx(64)};
//     ${responsiveFontSizes({ sm: 52, md: 58, lg: 64 })}
//   }

//   h2 {
//     font-size: ${pxToRem(32)};
//     line-height: ${remToPx(64) / remToPx(48)};
//     ${responsiveFontSizes({ sm: 40, md: 44, lg: 48 })}
//   }

//   h3 {
//     font-size: ${pxToRem(24)};
//     line-height: 1.5;
//     ${responsiveFontSizes({ sm: 26, md: 30, lg: 32 })}
//   }

//   h4 {
//     font-size: ${pxToRem(20)};
//     line-height: 1.5;
//     ${responsiveFontSizes({ sm: 20, md: 24, lg: 24 })}
//   }

//   h5 {
//     font-size: ${pxToRem(18)};
//     line-height: 1.5;
//     ${responsiveFontSizes({ sm: 19, md: 20, lg:

