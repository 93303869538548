import { Stack, Box } from "@mui/material";
import FormProvider, { RHFTextField } from "../components/hook-form";
import * as Yup from "yup";
import "yup-phone";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerCorporateCustomer } from "../services/authService";
import { useSnackbar } from "notistack";

const RegisterForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("First name is required"),
    surname: Yup.string().required("Surname is required"),
    msisdn: Yup.string().matches(/^07[89]\d{7}$/, 'Invalid phone number. Your msisdn is 10 digit that starts with 078 0r 079'),
    email: Yup.string()
      .email("Must be a vailid email address")
      .required("Email address is required"),
      paymentCapacity: Yup.number("Only digit is allowed").required(
      "Enter your monthly payment capacity"
    ),
    nationalIdNumber: Yup.string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(16, "Must be exactly 16 digits")
      .max(16, "Must be exactly 16 digits"),
  });

  const defaultValues = {
    name: null,
    surname: null,
    email: null,
    msisdn: null,
    nationalIdNumber: null,
    paymentCapacity: null,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    data.msisdn = `25${data.msisdn}`
    const payload = {
      customer: data,
      loan: {
        paymentCapacity: data?.paymentCapacity,
      },
      corporate: {
        corporateId: 20
      }
    };
        const res = await registerCorporateCustomer(payload)
        console.log(res?.data);
        if(res?.data?.status) {
          // reset();
          enqueueSnackbar('Your application was submitted successfully. You will be notified once approved.', {
            variant: 'success',
            persist: false
          });

        }else {
          enqueueSnackbar(`${res?.message ? res?.message : 'OOPS!!! Something went wrong. Please try again'}`, {
            variant: 'error',
            persist: false
          });
        }
        

  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
        }}
      >
        <RHFTextField name="name" label="First name" placeholder="Enter first name" />
        <RHFTextField name="surname" label="Last name" placeholder="Enter surname" />
        <RHFTextField name="email" label="Email" placeholder="Enter valid email address" />
        <RHFTextField name="msisdn" label="Telephone number" placeholder="Enter MTN Number (07x)" inputProps={{ maxLength: 10 }}/>
        <RHFTextField
          name="nationalIdNumber"
          label="ID number"
          inputProps={{ maxLength: 16 }}
        />
        <RHFTextField
          type="number"
          name="paymentCapacity"
          label="Payment Capacity / Month"
        />
      </Box>

      <Stack alignItems="flex-end" sx={{ mt: 3 }}>
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          color="inherit"
          size="large"
          loading={isSubmitting}
          sx={{
            bgcolor: "primary.main",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.black" : "grey.800",
            "&:hover": {
              bgcolor: "primary.main",
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          Create account
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export default RegisterForm;
