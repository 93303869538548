import {  useFormContext } from 'react-hook-form';
// @mui
import {
  Box,
  Card,
  Paper,
  Typography,
  RadioGroup,
  CardHeader,
  CardContent,
} from '@mui/material';
// components
import { useSelector } from 'react-redux';


export default function CheckoutDelivery() {
  const { control } = useFormContext();

  const product = useSelector(state => state.onboard?.product)
  console.log(product);
  const { id, typeName, memory, internalStorage, camera } = product;

  return (
    <Card>
      <CardHeader title="Device Information" />

      <CardContent>
       
         <RadioGroup>
              <Box
                gap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                {[
                  {
                    value: 0,
                    title: typeName,
                    description: `${memory} RAM, ${internalStorage} Storage, ${camera}`,
                  },
                  {
                    value: 2,
                    title: 'Bundle',
                    description: '200 Ubutumwa bugufi + 2000 Interineti" + 50 iminota / kukwezi',
                  },
                ].map((option) => (
                  <DeliveryOption
                    key={option.value}
                    option={option}
                    // isSelected={field.value === option.value}
                  />
                ))}
              </Box>
            </RadioGroup>
      </CardContent>
    </Card>
  );
}

function DeliveryOption({ option, isSelected }) {
  const { value, title, description } = option;

  return (
    <Paper
      variant="outlined"
      key={value}
      sx={{
        display: 'flex',
        alignItems: 'center',
        transition: (theme) => theme.transitions.create('all'),
        ...(isSelected && {
          boxShadow: (theme) => theme.customShadows.z20,
        }),
      }}
    >
      <Box sx={{ m: 3, p: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      </Box>
    </Paper>
  );
}
