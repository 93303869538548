import PropTypes from "prop-types";
import { Card, Link, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useDispatch } from "react-redux";

// components
import Iconify from "../iconify";
import Image from "../Image";
import { bgGradient } from "../styled/CssStyles";
import { addProduct } from "../../redux/slices/onboard";
import { useNavigate } from "react-router-dom";
import { IMAGE_URL } from "../../config/settings";
// import { convertToHyphen } from '../../utils/format';

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

const SUMMARY = [
  {
    title: "Interineti y'ukwezi",
    icon: "fluent-mdl2:internet-sharing",
  },
  {
    title: "Theft & Lost insurance",
    icon: "ic:round-verified-user",
  },
  {
    title: "Screen replacement and Phone Replacement",
    icon: "tabler:replace",
  },
];

export default function ShopProductCard({ product }) {
  const { plans, specs } = product;

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const linkTo = PATH_DASHBOARD.eCommerce.view(convertToHyphen(type_name));

  const checkDevice = () => {
    dispatch(addProduct(product));
    navigate("/device");
  };

  return (
    <Card
      sx={{
        // '&:hover .add-cart-btn': {
        //   opacity: 1,
        //   p: 0,
        // },
        cursor: "pointer",
        display: { md: "flex" },
        ...bgGradient({
          direction: "240deg",
          startColor: theme.palette.primary.main,
          endColor: theme.palette.primary.mainLighter,
        }),
      }}
      onClick={checkDevice}
    >
      <Stack
        sx={{
          position: "relative",
          p: 1,
          flex: 1,
          width: { md: "50%", backgroundColor: "white" },
        }}
      >
        <Image
          alt={specs.type_name}
          src={`${IMAGE_URL}/${specs?.photos[0]?.deviceTypeId}.jpg`}
          sx={{ borderRadius: 1.5, maxHeight: { xl: "280px", lg: "250px" } }}
        />
      </Stack>

      <Stack
        spacing={2}
        sx={{
          p: 3,
          width: { md: "50%" },
        }}
      >
        <Link
          // component={NextLink}
          // href={linkTo}
          color="black"
          variant="subtitle2"
          sx={{ fontSize: 15 }}
        >
          {specs.type_name}
        </Link>
        <Stack
          sx={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              borderBottom: () => `dashed 1px ${theme.palette.divider}`,
              color: "black",
            }}
          >
            This pack includes:
          </Typography>

          <Stack
            direction="row"
            sx={{
              marginTop: 1,
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            <Iconify icon={"fluent-mdl2:internet-sharing"} />
            <Typography
              variant="subtitle2"
              sx={{ marginLeft: 1, fontSize: "12px" }}
            >
              {`Bundle: ${plans[0]?.bandleData}MB, SMS:${plans[0]?.bandleSms}, Min: ${plans[0]?.bandleMinutes} `}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            sx={{
              marginTop: 1,
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            <Iconify icon={"ic:round-verified-user"} />
            <Typography
              variant="subtitle2"
              sx={{ marginLeft: 1, fontSize: "12px" }}
            >
              Theft & Lost insurance
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ borderTop: () => `dashed 1px ${theme.palette.divider}` }}>
          <Stack
            direction="row"
            sx={{
              marginTop: 1,
              alignItems: "center",
              fontSize: "12px",
            }}
         
          >
            <Iconify icon="mdi:recurring-payment" />
            <Typography
              variant="subtitle2"
              sx={{ marginLeft: 1, fontSize: "12px" }}
            >
              <span style={{ fontWeight: "bold" }}>Repayment: </span>{" "}
              {`${plans[0]?.dailyPayment}Frw daily`}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            sx={{
              marginTop: 1,
              alignItems: "center",
              fontSize: "12px",
            }}
       
          >
            <Iconify icon="mdi:payment-clock" />
            <Typography
              variant="subtitle2"
              sx={{ marginLeft: 1, fontSize: "12px" }}
            >
              <span style={{ fontWeight: "bold" }}>Period: </span>{" "}
              {`${plans[0]?.periodicity} Months`}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
