import { useState } from 'react';

import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';

import { CustomAvatar } from '../../../components/custom-avatar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { useDispatch, useSelector } from 'react-redux';
import { removeCurrentUser, removeUser } from '../../../redux/slices/userSlice';
import { removeProduct } from '../../../redux/slices/onboard';
import { useNavigate } from 'react-router-dom';
// import { removeProduct } from 'src/redux/slices/onboard';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch()
  const user = useSelector((state) => state?.user?.user);
  const navigate = useNavigate()

  // console.log(user);

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    dispatch(removeUser())
    dispatch(removeProduct())
    dispatch(removeCurrentUser())
    handleClosePopover();
    navigate("/")
  };

  const handleClickItem = (path) => {
    handleClosePopover();

  };

  // const OPTIONS = [
  //   {
  //     label: user?.email,
  //     linkTo: '/',
  //   },
  //   {
  //     label: 'Profile',
  //     linkTo: "/",
  //   },
  //   {
  //     label: 'Loan',
  //     linkTo: "/",
  //   },
  // ];
  


  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar src={user?.photo} alt={user?.saccoAccountName} name={user?.saccoAccountName} />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.saccoAccountName}
      
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            25{user?.msisdn}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 1 }}>
   
            <MenuItem>
              Act. No: {user?.saccoAccountNumber}
            </MenuItem>
  
        </Stack>

        {/* <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1, }}>
          <span style={{color:"red"}}><strong>Logout</strong></span>
        </MenuItem>
      </MenuPopover>
    </>
  );
}
