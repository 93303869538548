import * as Yup from "yup";
import { useCallback, useEffect, useMemo } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Card,
  Grid,
  Typography,
  Button,
} from "@mui/material";
// utils
import { fData } from "../../utils/formatNumber";
import { useSnackbar } from "../snackbar";
import FormProvider, {
  RHFUploadAvatar,
  RHFTextField,
  RHFSelect,
  // redux
} from "../hook-form";
import { useDispatch, useSelector } from "react-redux";
import { nextStep } from "../../redux/slices/product";
import { ArrowForward } from "@mui/icons-material";
import { getCells, getDistricts, getSectors } from "../../utils/data";
import { resizeFile } from "../../utils/format";
import { saveCustomer } from "../../redux/slices/onboard";

export default function UserNewEditForm({ isEdit = true, currentUser }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.user?.customer);
  const customer = useSelector((state) => state?.onboard?.customer);
  const loanId = useSelector(
    (state) => state?.user?.user?.loanApplication?.loanId
  );
  const deviceTypeId = useSelector(
    (state) => state.onboard?.product?.deviceTypeId
  );


  const sign = useSelector((state) => state?.products?.sign);

  console.log(customer);

  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    address: Yup.string("").required("Address is required"),
    gender: Yup.string("").required("Gender is required"),
    martialStatus: Yup.string().required("Marital Status is required"),
    province: Yup.string().required("Province is required"),
    district: Yup.string().required("District is required"),
    sector: Yup.string().required("Sector is required"),
    cell: Yup.string().required("Cell Status is required"),
    village: Yup.string().required("Village is required"),
    dob: Yup.string().required("Date of birth is required"),
    avatarUrl: Yup.string().required("Avatar is required"),
  });

  const defaultValues = useMemo(
    () => ({
      customerId: user?.customerId,
      name: user?.firstName,
      surname: user?.surname,
      nationalIdNumber: user?.nationalIdNumber,
      msisdn: user?.msisdn,
      email: user?.email,
      address: customer?.address,
      gender: customer?.gender,
      martialStatus: customer?.martialStatus,
      province: customer?.province,
      district: customer?.district,
      sector: customer?.sector,
      cell: customer?.cell,
      village: customer?.village,
      dob: customer?.dob,
      spouseMsisdn: customer?.spouseMsisdn,
      spouseNationalId: customer?.spouseNationalId,
      spouseName: customer?.spouseName,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  // useEffect(() => {
  //   if (isEdit && currentUser) {
  //     reset(defaultValues);
  //   }
  //   if (!isEdit) {
  //     reset(defaultValues);
  //   }

  // }, [isEdit, currentUser]);

  const onSubmit = async (data) => {
    dispatch(saveCustomer(data))
    dispatch(nextStep())
    
  };

  const handleDrop =  useCallback(
    async(acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      const image = await resizeFile(newFile)
    //  console.log(image);

      if (file) {
        setValue("avatarUrl", image, { shouldValidate: true });
      }
    },
    [setValue] 
  );

  useEffect(() => {
    setValue("district", "");
    setValue("sector", "");
    setValue("cell", "");
  }, [values?.province]);

  useEffect(() => {
    setValue("sector", "");
    setValue("cell", "");
  }, [values?.district]);

  useEffect(() => {
    setValue("cell", "");
  }, [values?.sector]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3 }}>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                // disabled
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: "auto",
                      display: "block",
                      textAlign: "center",
                      color: "text.secondary",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
            }}
          >
            <RHFTextField
              name="name"
              label="First name"
              value={user?.firstName}
              disabled
            />
            <RHFTextField
              name="surname"
              label="Surname"
              value={user?.surname}
              disabled
            />
            <RHFTextField
              name="nationalIdNumber"
              label="ID Number"
              value={user?.nationalIdNumber}
              disabled
            />
            <RHFTextField
              name="msisdn"
              label="MSISDN"
              value={user?.msisdn}
              disabled
            />
            <RHFTextField
              name="email"
              label="Email"
              value={user?.email}
              disabled
            />
            <RHFTextField type="date" name="dob" label="Date of birth" value={customer?.dob} />
            <RHFSelect native name="gender" label="Gender" placeholder="Gender" value={customer?.gender}>
              <option value="" />
              {[{id: "M", value:"Male"}, {id: "F", value:"Female"}].map((item) => (
                <option key={item.id} value={item.id}>
                  {item.value}
                </option>
              ))}
            </RHFSelect>
            <RHFSelect
              native
              name="martialStatus"
              label="Marital Status"
              placeholder="Marital Status"
              value={customer?.martialStatus}
            >
              <option value="" />
              {[{id:"M", value:"Married"}, {id:"S", value:"Single"}, {id:"D", value:"Divorced"}, {id:"W", value:"Widowed"}].map((item) => (
                <option key={item.id} value={item.id}>
                  {item.value}
                </option>
              ))}
            </RHFSelect>
            {values?.martialStatus === "M" && (
              <>
                <RHFTextField name="spouseName" label="Spouse Name" />
                <RHFTextField
                  name="spouseNationalId"
                  label="Spouse ID number"
                  inputProps={{ maxLength: 16 }}
                />
                <RHFTextField
                  name="spousePhoneNumber"
                  label="Spouse phone number"
                />
              </>
            )}

            <RHFSelect
              native
              name="province"
              label="Province"
              placeholder="Province"
             
            >
              <option value="" />
              {["Kigali", "East", "West", "North", "South"].map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </RHFSelect>
            <RHFSelect
              native
              name="district"
              label="District"
              placeholder="District"
            
            >
              <option value="" />
              {getDistricts(values?.province).map((item) => (
                <option key={item} value={item} >
                  {item}
                </option>
              ))}
            </RHFSelect>
            <RHFSelect native name="sector" label="Sector" placeholder="Sector" >
              <option value="" />
              {getSectors(values?.province, values?.district).map((item) => (
                <option key={item} value={item} >
                  {item}
                </option>
              ))}
            </RHFSelect>
            <RHFSelect native name="cell" label="Cell" placeholder="Cell"  >
              <option value="" />
              {getCells(values?.province, values?.district, values?.sector).map(
                (item) => (
                  <option key={item} value={item} >
                    {item}
                  </option>
                )
              )}
            </RHFSelect>
            <RHFTextField name="village" label="Village"  value={customer?.village} />
            <RHFTextField name="address" label="Address"  value={customer?.district} />
          </Box>

          {/* <Stack alignItems="flex-end" sx={{ mt: 3 }}>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              color="inherit"
              size="large"
              loading={isSubmitting}
              sx={{
                bgcolor: "primary.main",
                color: (theme) =>
                  theme.palette.mode === "light" ? "common.black" : "grey.800",
                "&:hover": {
                  bgcolor: "primary.main",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                },
              }}
            >
              Make loan request
            </LoadingButton>
          </Stack> */}
        </Grid>

        <Grid container style={{ marginTop: 40, marginLeft: 20 }}>
          <Grid
            item
            lg={12}
            container
            alignItems="center"
            justifyContent="space-between"
          >
          </Grid>
        </Grid>
        <Grid item lg={12} container alignItems="center" justifyContent="flex-end">
          <Button
            type="submit" 
            size="large"
            endIcon={<ArrowForward />}
            variant="contained"
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
